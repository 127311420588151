import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet"
import FullscreenHeader from "../components/Content/FullscreenHeader";
import Layout from "../Layout";
import '../components/Contact/Contact.css';

const Contact = ({ location, ...props }) => {
  const title = `Get in touch with Michael Lisboa to talk about great User Experience.`;
  return (
    <Layout location={location}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content="Let's do great things together - Contact Michael." />
        <meta property="og:description" content="Let's do great things together - Contact Michael." />
        <meta name="twitter:description" content="Let's do great things together - Contact Michael." />
      </Helmet>
      {/* <ContactView location={location.pathname} props={props} /> */}

      <FullscreenHeader
        media={props.data.allFile.nodes[0]}
        content={'<h1 class="h1__xlarge">Let\'s do<br />great things.</h1>'}
      />
    </Layout>
  )
}

export default Contact;

export const contactQuery = graphql`
    query contactQuery {
        allFile(filter: {name: {eq: "mypic_contact"}}) {
            nodes {
              name
              publicURL
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              internal {
                mediaType
              }
            }
          }
        allContentfulBlog {
            nodes {
                date
                mainImage {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyContentfulFluid_withWebp
                    }
                    description
                }
                title
                subtitle {
                    subtitle
                }
                slug
                topic
            }
        }
    }`
